import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import BottomNavigation from "../component/bottom-navigation/BottomNavigation";
import "../assets/styles/Rules.scss"
import { supabase } from "../utils/supabase";

const Rules = () => {
    const [isAgreed, setAgreed] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [code, setCode] = useState('');
    const [theme, setTheme] = useState('');

    const history = useNavigate();
    useEffect(()=>{
        const agreed = localStorage.getItem("agreed");      
        getRules();   
        if(JSON.parse(agreed))setAgreed(agreed);
        localStorage.setItem("activeHole", 1);  
        
        getTheme();
    },[])
    const onUnderstand = () => {
        localStorage.setItem("agreed", true);  
        setAgreed(true);
        history("/players")
    }

    const getRules = async () => {
        let { data: rules } = await supabase
        .from('rules')
        .select('*');

        setCode( JSON.parse(rules[0].rules) )
    }

    const getTheme = async () => {
        // const { data, error } = await supabase
        // .from('theme')
        // .select();
        // if(data[0]?.theme){
        //     setTheme(data[0].theme);
        // }
    }


  useEffect(() => {
    const setThemeColor = () => {
      const themeColor = '#fff';
      const metaThemeColor = document.querySelector('meta[name="theme-color"]');
      if (metaThemeColor) {
        metaThemeColor.setAttribute('content', themeColor);
      }
    };

    // Call the function initially
    setThemeColor();

    // Add event listener for changes in color scheme preference
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', setThemeColor);


    
    setTimeout(()=>{
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);
        setIsAtBottom(windowHeight >= documentHeight);
    },1000)
    


    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);
        setIsAtBottom(windowHeight + scrollTop+50 >= documentHeight);
      };  
      
    window.addEventListener('scroll', handleScroll);       
    
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', setThemeColor);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])


  function createMarkup() {
    return {__html: code};
  }
   


    return <><div class={`rules_screen ${theme}`}>
        <h2 className="VP_h2_heading">PUTT PUTT RULES</h2>
        <div className="content" dangerouslySetInnerHTML={createMarkup() } />
        <div className="content" >                        
            
            {/* <div className="text_block">
                <h4>PICK YOUR LEVEL</h4>
                <p>Each hole has three levels of difficulty.</p>
                <p>Choose which level you would like to play for the round.</p>
            </div>
            <div className="level">
                <span>Beginner</span>
                <span>Intermediate</span>
                <span>Advanced</span>
            </div>
            <div className="text_block">
                <h4>STROKE LIMIT</h4>
                <p>6 per hole, then move on.</p>
               
            </div>
            <div className="text_block">
                <h4>WRONG HOLE</h4>
                <p>Add one stroke to your score.</p>
            </div>
            <div className="text_block">
                <h4>SAND AND WATER HAZARDS</h4>
                <p>Retrieve ball and place one club head from where it entered the hazard.</p>
                <p>Add one penalty stroke to your score.</p>
                <p>Do not hit out of hazards.</p>
            </div>
            <div className="text_block">
                <h4>OBSTACLES</h4>
                <p>The ball may be moved up to one club head distance from obstacles or side walls, in direction of fairway.</p>
            </div>
            <div className="text_block">
                <h4>SAFETY</h4>
                <p>Do not enter water hazards, please retrieve the ball with retriever provided.</p>
                <p>If the ball is irretrievable please return to the Golf Shop for a replacement ball.</p>
                <p>No glass permitted on the course. Keep off rocks and out of gardens.</p>
                <p>No smoking.</p>
                <p>No swings above waist height.</p>
                <p>Maximum 4 players per group.</p>
            </div> */}

        { !isAgreed &&
            <div className="bottom_navigation">
                    <a href="/" > <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                                   <path d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5L11 4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464466C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z" fill="#5D5D5D"/>
                                  </svg> BACK
                    </a>
                    {
                        !isAtBottom ? <button class="button disabled">I UNDERSTAND</button> : <button class="button" onClick={()=>onUnderstand()}>I UNDERSTAND</button>
                    }                    
            </div>
        }
        </div>
    </div>
    {isAgreed && <BottomNavigation/>}
    </>
}

export default Rules;
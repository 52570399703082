import React,{useState, useEffect} from "react";
import DashboardComponent from "../../component/dashboard/Dashboard";
import { supabase } from "../../utils/supabase";
import add from "../../assets/images/add_small.png";
import remove from "../../assets/images/remove.png";
import save from "../../assets/images/save.png";
import "../../assets/styles/OrderModalSettings.scss";
import { Store } from 'react-notifications-component'

export default function OrderModalSettings(){
    const [allRows, setAllRows] = useState([]);

    const getAllHoledata = async () => {
        let { data: order_modal_settings, error } = await supabase
        .from('order_modal_settings')
        .select('*');

        const rowData = order_modal_settings.map( (row) => ({ hole : row.hole, url : row.url}))
        setAllRows(rowData);
    }

    useEffect(()=>{
        getAllHoledata()                
    },[])

    const createEntry = () => {
        setAllRows(prev => ([...prev, { hole : '', url : ''} ]))   
    }

    const removeRow = (rowIndex) => {
        const temp = allRows;
        temp.splice(rowIndex, 1);
        console.log('--temp--', temp)
        setAllRows([...temp]);
    }

    const onValuesChange = (value, attribute ,rowIndex) => {
        if(attribute === "hole" && ( value < 1 || value > 18)){}
        else{
            const temp = allRows;
            temp[rowIndex][attribute] = value;
            setAllRows([...temp]);
        }        
    }

    const hasDuplicates = (arrayToCheck) => {
        const holeSet = new Set();
      
        for (const obj of arrayToCheck) {
          if (holeSet.has(obj.hole)) {
            // Duplicate found
            return true;
          }
          holeSet.add(obj.hole);
        }
      
        // No duplicates found
        return false;
      };

    const saveData = async () => { 


        const resultUnique = hasDuplicates(allRows);

        if(resultUnique){
            Store.addNotification({
                title: "Error",
                message: "Please remove repeated hole values!",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 4000,
                  onScreen: true,
                  click: true,
                  showIcon: true,
                  pauseOnHover: true
                }
            })
        } else {
            const { error } = await supabase
            .from('order_modal_settings')
            .delete()
            .neq('hole', 'Not equal to')
    
                    
            const { data, error:insertError } = await supabase
            .from('order_modal_settings')
            .insert(allRows)
            .select()                            
    
            if(data){
                Store.addNotification({
                    title: "Success",
                    message: "Data updated successfully",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 4000,
                      onScreen: true,
                      click: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                })
            }
        }             
    }

    return <DashboardComponent>
        <div className="container_order_settings">
            <h3>Order modal settings</h3>

            <div className="create_entry" onClick={()=>createEntry()}>
                <img src={add} style={{width: "15px", height: "15px"}}/><button>Add Entry</button>
            </div>
                <table>
                    <thead>
                        <tr>
                            <th>Index</th>
                            <th>Hole</th>
                            <th>URL</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allRows.map(((row, index)=> {
                                return <tr className="field-row">                
                                                <td> <span>{index+1}</span> </td>
                                                <td> <input type="number" min="1" max="18" value={row.hole} onChange={(e)=>onValuesChange(e.target.value, "hole", index)}/> </td>
                                                <td> <input type="text" value={row.url} onChange={(e)=>onValuesChange(e.target.value, "url", index)}/> </td>
                                                <td> <img className="remove-icons" src={remove} onClick={()=>removeRow(index)}/> </td>
                                        </tr>
                            }))
                        }
                    </tbody>
            
                </table>
                <br/><br/>

                <div className="create_entry" onClick={()=>saveData()}>
                        <img src={save} style={{width: "15px"}}/><button>Save Data</button>
                </div> <small>Don't forget to save after you make changes in above data.</small>
            

        </div>            
    </DashboardComponent>
}
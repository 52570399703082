import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from "../../utils/supabase";
import  "./BottomNavigation.scss";
const BottomNavigation = () => {
    const history = useNavigate();
    const location = useLocation();
    const menus = {
        rules: false,
        players: false,
        score: false,
        scoreCard: false,
        endgame: false
    };
    const [endState, setEndState] = useState(false);
    const [pageState, setPageState] = useState(menus);

     
     useEffect(()=>{
        if(location.pathname.includes('/rules')){
            setPageState({...menus, rules: true})
        }
        if(location.pathname.includes('/players')){
            setPageState({...menus, players: true})
        }
        if(location.pathname.includes('/enter-score')){
            setPageState({...menus, score: true})
        }
        if(location.pathname.includes('/view-score')){
            setPageState({...menus, scoreCard: true})
        }
        if(location.pathname.includes('/result')){
            setPageState({...menus, endgame: true})
        }
     },[location])

    const setmodalState = () => {
        const game_data = localStorage.getItem("game_data");        
        const agreed = localStorage.getItem("agreed");

        if(game_data === null || game_data.length < 1){
             if(agreed){
                history('/players');
            }
            else{
                history('/');
            }
        }else{
            setEndState(!endState)
        }
        
    }

    const getColor = (locationString) => {
        if(location.pathname.includes(locationString)){
            return "white";
        }else{
            return "rgba(255, 255, 255, 0.5)";
        }
    }
    
    const endGame = async () => {
        const game_data = localStorage.getItem("game_data");
        if(game_data){
            
            const { data } = await supabase
            .from('games')
            .update({ status: 'completed' })
            .eq('id', JSON.parse(game_data)[0].id)
            .select()

            localStorage.setItem("activeHole", 1);
            
            if(data){
               history("/result")
            }            
        }
    }

    return <>
        <div className="bottomNavigationHolder">

            <span onClick={()=> history("/rules")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="27" viewBox="0 0 30 27" fill="none">
                <path d="M6.4602 7.75695H9.29466C9.68676 7.75695 10.0033 7.44044 10.0033 7.04834C10.0033 6.65624 9.68676 6.33972 9.29466 6.33972H6.4602C6.0681 6.33972 5.75159 6.65624 5.75159 7.04834C5.75159 7.44044 6.0681 7.75695 6.4602 7.75695Z" fill="white" fillOpacity={pageState.rules ? "1" : "0.5"}/>
                <path d="M12.9227 7.75695H23.5519C23.944 7.75695 24.2606 7.44044 24.2606 7.04834C24.2606 6.65624 23.944 6.33972 23.5519 6.33972H12.9227C12.5306 6.33972 12.2141 6.65624 12.2141 7.04834C12.2141 7.44044 12.5306 7.75695 12.9227 7.75695Z" fill="white" fillOpacity={pageState.rules ? "1" : "0.5"}/>
                <path d="M6.4602 11.8433H9.29466C9.68676 11.8433 10.0033 11.5268 10.0033 11.1347C10.0033 10.7426 9.68676 10.4261 9.29466 10.4261H6.4602C6.0681 10.4261 5.75159 10.7426 5.75159 11.1347C5.75159 11.5268 6.0681 11.8433 6.4602 11.8433Z" fill="white" fillOpacity={pageState.rules ? "1" : "0.5"}/>
                <path d="M12.9227 11.8433H23.5519C23.944 11.8433 24.2606 11.5268 24.2606 11.1347C24.2606 10.7426 23.944 10.4261 23.5519 10.4261H12.9227C12.5306 10.4261 12.2141 10.7426 12.2141 11.1347C12.2141 11.5268 12.5306 11.8433 12.9227 11.8433Z" fill="white" fillOpacity={pageState.rules ? "1" : "0.5"}/>
                <path d="M6.4602 15.9273H9.29466C9.68676 15.9273 10.0033 15.6108 10.0033 15.2187C10.0033 14.8266 9.68676 14.5101 9.29466 14.5101H6.4602C6.0681 14.5101 5.75159 14.8266 5.75159 15.2187C5.75159 15.6108 6.0681 15.9273 6.4602 15.9273Z" fill="white" fillOpacity={pageState.rules ? "1" : "0.5"}/>
                <path d="M12.9227 15.9273H23.5519C23.944 15.9273 24.2606 15.6108 24.2606 15.2187C24.2606 14.8266 23.944 14.5101 23.5519 14.5101H12.9227C12.5306 14.5101 12.2141 14.8266 12.2141 15.2187C12.2141 15.6108 12.5306 15.9273 12.9227 15.9273Z" fill="white" fillOpacity={pageState.rules ? "1" : "0.5"}/>
                <path d="M27.9973 18.2279V2.12584C27.9973 0.951905 27.0454 0 25.8715 0H4.14066C2.96672 0 2.01482 0.951905 2.01482 2.12584V18.2303C0.876312 18.3909 0 19.3664 0 20.5498V24.1756C0 25.47 1.04875 26.5187 2.34315 26.5187H27.6643C28.9587 26.5187 30.0075 25.47 30.0075 24.1756V20.5498C30.0075 19.3664 29.1311 18.3933 27.9926 18.2303L27.9973 18.2279ZM4.14066 1.41723H25.8715C26.2612 1.41723 26.5801 1.73611 26.5801 2.12584V18.2043H26.3109C25.6896 18.2043 25.092 18.4523 24.6527 18.8917L22.5174 21.027C22.0781 21.4663 21.4805 21.7143 20.8592 21.7143H9.14821C8.52699 21.7143 7.92939 21.4663 7.49004 21.027L5.35476 18.8917C4.91542 18.4523 4.31781 18.2043 3.6966 18.2043H3.42733V2.12584C3.42733 1.73611 3.74621 1.41723 4.13595 1.41723H4.14066ZM28.5949 24.1732C28.5949 24.6834 28.1792 25.0991 27.669 25.0991H2.34788C1.83767 25.0991 1.42196 24.6834 1.42196 24.1732V20.5475C1.42196 20.0373 1.83767 19.6215 2.34788 19.6215H3.70369C3.9517 19.6215 4.18318 19.7184 4.36033 19.8932L6.49563 22.0285C7.20661 22.7394 8.15143 23.1292 9.1553 23.1292H20.8663C21.8702 23.1292 22.815 22.7371 23.526 22.0285L25.6613 19.8932C25.8361 19.7184 26.0699 19.6215 26.3179 19.6215H27.6737C28.1839 19.6215 28.5997 20.0373 28.5997 20.5475V24.1732H28.5949Z" fill="white" fillOpacity={pageState.rules ? "1" : "0.5"}/>
                </svg> <p style={{color: getColor('rules') }}>Rules</p>
            </span>
            

            <span onClick={()=> history("/players")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="30" viewBox="0 0 23 30" fill="none">
                <path d="M19.6546 6.35863C20.3726 5.68781 20.8261 4.73354 20.8261 3.67535C20.8261 1.64871 19.1774 0 17.1508 0C15.1241 0 13.4754 1.64871 13.4754 3.67535C13.4754 4.73354 13.9289 5.68781 14.647 6.35863C13.2487 6.87828 12.2495 8.22229 12.2495 9.80014V13.4755C12.2495 13.8133 12.5235 14.0873 12.8613 14.0873H21.4355C21.7733 14.0873 22.0473 13.8133 22.0473 13.4755V9.80014C22.0473 8.22465 21.0482 6.88065 19.6498 6.35863H19.6546ZM17.1508 1.22354C18.5019 1.22354 19.6002 2.32189 19.6002 3.67298C19.6002 5.02408 18.5019 6.12243 17.1508 6.12243C15.7997 6.12243 14.7013 5.02408 14.7013 3.67298C14.7013 2.32189 15.7997 1.22354 17.1508 1.22354ZM20.8261 12.859H13.4778V9.79777C13.4778 8.44668 14.5761 7.34833 15.9272 7.34833H18.3767C19.7278 7.34833 20.8261 8.44668 20.8261 9.79777V12.859Z" fill="white" fillOpacity={pageState.players ? "1" : "0.5"}/>
                <path d="M19.6546 21.6671C20.3726 20.9963 20.8261 20.042 20.8261 18.9838C20.8261 16.9572 19.1774 15.3084 17.1508 15.3084C15.1241 15.3084 13.4754 16.9572 13.4754 18.9838C13.4754 20.042 13.9289 20.9963 14.647 21.6671C13.2487 22.1867 12.2495 23.5307 12.2495 25.1086V28.7839C12.2495 29.1217 12.5235 29.3957 12.8613 29.3957H21.4355C21.7733 29.3957 22.0473 29.1217 22.0473 28.7839V25.1086C22.0473 23.5331 21.0482 22.1891 19.6498 21.6671H19.6546ZM17.1508 16.5344C18.5019 16.5344 19.6002 17.6327 19.6002 18.9838C19.6002 20.3349 18.5019 21.4332 17.1508 21.4332C15.7997 21.4332 14.7013 20.3349 14.7013 18.9838C14.7013 17.6327 15.7997 16.5344 17.1508 16.5344ZM20.8261 28.1698H13.4778V25.1086C13.4778 23.7575 14.5761 22.6591 15.9272 22.6591H18.3767C19.7278 22.6591 20.8261 23.7575 20.8261 25.1086V28.1698Z" fill="white" fillOpacity={pageState.players ? "1" : "0.5"}/>
                <path d="M7.40504 21.6671C8.12311 20.9963 8.57661 20.042 8.57661 18.9838C8.57661 16.9572 6.92789 15.3084 4.90125 15.3084C2.87461 15.3084 1.22592 16.9572 1.22592 18.9838C1.22592 20.042 1.67942 20.9963 2.39748 21.6671C0.999152 22.1867 0 23.5307 0 25.1086V28.7839C0 29.1217 0.274018 29.3957 0.611791 29.3957H9.18601C9.52378 29.3957 9.7978 29.1217 9.7978 28.7839V25.1086C9.7978 23.5331 8.79865 22.1891 7.40031 21.6671H7.40504ZM4.90361 16.5344C6.2547 16.5344 7.35306 17.6327 7.35306 18.9838C7.35306 20.3349 6.2547 21.4332 4.90361 21.4332C3.55252 21.4332 2.45417 20.3349 2.45417 18.9838C2.45417 17.6327 3.55252 16.5344 4.90361 16.5344ZM8.57897 28.1698H1.23065V25.1086C1.23065 23.7575 2.329 22.6591 3.68009 22.6591H6.12953C7.48062 22.6591 8.57897 23.7575 8.57897 25.1086V28.1698Z" fill="white" fillOpacity={pageState.players ? "1" : "0.5"}/>
                <path d="M7.40504 6.35863C8.12311 5.68781 8.57661 4.73354 8.57661 3.67535C8.57661 1.64871 6.92789 0 4.90125 0C2.87461 0 1.22592 1.64871 1.22592 3.67535C1.22592 4.73354 1.67942 5.68781 2.39748 6.35863C0.999152 6.87828 0 8.22229 0 9.80014V13.4755C0 13.8133 0.274018 14.0873 0.611791 14.0873H9.18601C9.52378 14.0873 9.7978 13.8133 9.7978 13.4755V9.80014C9.7978 8.22465 8.79865 6.88065 7.40031 6.35863H7.40504ZM4.90361 1.22354C6.2547 1.22354 7.35306 2.32189 7.35306 3.67298C7.35306 5.02408 6.2547 6.12243 4.90361 6.12243C3.55252 6.12243 2.45417 5.02408 2.45417 3.67298C2.45417 2.32189 3.55252 1.22354 4.90361 1.22354ZM8.57897 12.859H1.23065V9.79777C1.23065 8.44668 2.329 7.34833 3.68009 7.34833H6.12953C7.48062 7.34833 8.57897 8.44668 8.57897 9.79777V12.859Z" fill="white" fillOpacity={pageState.players ? "1" : "0.5"}/>
                </svg> <p style={{color: getColor('players') }}>Players</p>
            </span>

            <span onClick={()=> history("/enter-score")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="33" viewBox="0 0 19 33" fill="none">
                <path d="M16.449 6.23392L18.847 2.92596C19.0226 2.68457 19.0469 2.36358 18.9064 2.10096C18.766 1.83834 18.4906 1.67122 18.1854 1.67122H8.5904V0.79582C8.5904 0.355466 8.22853 0 7.78024 0C7.33195 0 6.97008 0.355466 6.97008 0.79582V23.3334C3.00299 23.5775 0 25.6068 0 28.1535C0 30.8699 3.41888 33 7.78024 33C12.1416 33 15.5605 30.8725 15.5605 28.1535C15.5605 25.6068 12.5575 23.5775 8.5904 23.3334V10.8046H18.1881C18.4906 10.8046 18.7687 10.6375 18.9091 10.3748C19.0496 10.1122 19.0253 9.79124 18.8497 9.54984L16.4517 6.24188L16.449 6.23392ZM13.9402 28.1508C13.9402 29.9149 11.1181 31.4057 7.78024 31.4057C4.44238 31.4057 1.62032 29.9149 1.62032 28.1508C1.62032 26.5326 3.99679 25.1453 6.97008 24.9277V27.7051C6.97008 28.1455 7.33195 28.501 7.78024 28.501C8.22853 28.501 8.5904 28.1455 8.5904 27.7051V24.9277C11.5637 25.1453 13.9402 26.5326 13.9402 28.1508ZM8.5904 9.20764V3.26021H16.6164L14.7962 5.77235C14.5964 6.04823 14.5964 6.41961 14.7962 6.6955L16.6164 9.20764H8.5904Z" fill="white" fillOpacity={pageState.score ? "1" : "0.5"}/>
                </svg> <a href="/enter-score" style={{color: getColor('enter-score') }}>Add Score</a>
            </span>

            <span onClick={()=> history("/view-score")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="34" viewBox="0 0 42 34" fill="none">
                <path d="M38.1447 0H9.70093C9.30647 0 8.9309 0.165343 8.66163 0.453513L0.377928 9.38678C0.134637 9.64897 0 9.99382 0 10.3505V24.3173C0 26.1762 1.51171 27.6879 3.37064 27.6879H18.7783C19.1704 27.6879 19.4869 27.3714 19.4869 26.9793C19.4869 26.5872 19.1704 26.2707 18.7783 26.2707H3.37064C2.29119 26.2707 1.41723 25.3967 1.41723 24.3173V10.3505H7.74988C8.82934 10.3505 9.70329 9.47654 9.70329 8.39708V1.41723H38.1447C39.2242 1.41723 40.0981 2.29119 40.0981 3.37064V24.3173C40.0981 25.3967 39.2242 26.2707 38.1447 26.2707H29.8138C29.4217 26.2707 29.1052 26.5872 29.1052 26.9793C29.1052 27.3714 29.4217 27.6879 29.8138 27.6879H38.1447C40.0036 27.6879 41.5154 26.1762 41.5154 24.3173V3.37064C41.5154 1.51171 40.0036 0 38.1447 0Z" fill="white" fillOpacity={pageState.scoreCard ? "1" : "0.5"}/>
                <path d="M30.102 29.0296L24.4898 23.4173L19.5625 18.4901L18.0248 16.9524C17.8784 16.806 17.7036 16.6973 17.5076 16.6312L14.6849 15.6746C14.5385 15.625 14.392 15.6013 14.2479 15.6013C13.3834 15.6013 12.689 16.4588 12.9842 17.3587L13.9196 20.2121C13.9857 20.4105 14.0968 20.5923 14.2456 20.7411L15.7785 22.2741L26.3818 32.8774C26.8707 33.3663 27.688 33.3379 28.2124 32.8159L30.1044 30.9239C30.6264 30.4019 30.6547 29.5823 30.1658 29.0934L30.1044 29.0319L30.102 29.0296ZM14.3778 17.0658L17.0328 17.9657L17.6753 18.6082L16.8084 19.4751L15.8943 20.3892L15.2565 19.7515L14.3755 17.0658H14.3778Z" fill="white" fillOpacity={pageState.scoreCard ? "1" : "0.5"}/>
                <path d="M36.4936 4.74298H13.5818C13.1897 4.74298 12.8732 5.0595 12.8732 5.4516C12.8732 5.8437 13.1897 6.16021 13.5818 6.16021H36.4936C36.8857 6.16021 37.2023 5.8437 37.2023 5.4516C37.2023 5.0595 36.8857 4.74298 36.4936 4.74298Z" fill="white" fillOpacity={pageState.scoreCard ? "1" : "0.5"}/>
                <path d="M27.9903 8.96396H22.0852C21.6931 8.96396 21.3766 9.28047 21.3766 9.67257C21.3766 10.0647 21.6931 10.3812 22.0852 10.3812H27.9903C28.3824 10.3812 28.6989 10.0647 28.6989 9.67257C28.6989 9.28047 28.3824 8.96396 27.9903 8.96396Z" fill="white" fillOpacity={pageState.scoreCard ? "1" : "0.5"}/>
                <path d="M27.9903 13.1849H22.0852C21.6931 13.1849 21.3766 13.5015 21.3766 13.8936C21.3766 14.2857 21.6931 14.6022 22.0852 14.6022H27.9903C28.3824 14.6022 28.6989 14.2857 28.6989 13.8936C28.6989 13.5015 28.3824 13.1849 27.9903 13.1849Z" fill="white" fillOpacity={pageState.scoreCard ? "1" : "0.5"}/>
                <path d="M13.5818 10.3812H19.4869C19.879 10.3812 20.1955 10.0647 20.1955 9.67257C20.1955 9.28047 19.879 8.96396 19.4869 8.96396H13.5818C13.1897 8.96396 12.8732 9.28047 12.8732 9.67257C12.8732 10.0647 13.1897 10.3812 13.5818 10.3812Z" fill="white" fillOpacity={pageState.scoreCard ? "1" : "0.5"}/>
                <path d="M13.5818 14.6022H19.4869C19.879 14.6022 20.1955 14.2857 20.1955 13.8936C20.1955 13.5015 19.879 13.1849 19.4869 13.1849H13.5818C13.1897 13.1849 12.8732 13.5015 12.8732 13.8936C12.8732 14.2857 13.1897 14.6022 13.5818 14.6022Z" fill="white" fillOpacity={pageState.scoreCard ? "1" : "0.5"}/>
                <path d="M36.4936 8.96396H30.5885C30.1964 8.96396 29.8799 9.28047 29.8799 9.67257C29.8799 10.0647 30.1964 10.3812 30.5885 10.3812H36.4936C36.8857 10.3812 37.2022 10.0647 37.2022 9.67257C37.2022 9.28047 36.8857 8.96396 36.4936 8.96396Z" fill="white" fillOpacity={pageState.scoreCard ? "1" : "0.5"}/>
                <path d="M36.4936 13.1849H30.5885C30.1964 13.1849 29.8799 13.5015 29.8799 13.8936C29.8799 14.2857 30.1964 14.6022 30.5885 14.6022H36.4936C36.8857 14.6022 37.2022 14.2857 37.2022 13.8936C37.2022 13.5015 36.8857 13.1849 36.4936 13.1849Z" fill="white" fillOpacity={pageState.scoreCard ? "1" : "0.5"}/>
                </svg> <a href="/view-score" style={{color: getColor('view-score') }}>Scorecard</a>
            </span>

            <span onClick={() => setmodalState()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <path d="M28 8.59863C28 6.65021 26.3986 5.06401 24.4316 5.06401H22.5673V2.33906C22.5673 1.04879 21.5085 0 20.2059 0H7.53836C6.23575 0 5.17694 1.04879 5.17694 2.33906V5.06401H3.5684C1.60135 5.06401 0 6.65021 0 8.6081C0 11.4964 2.29686 13.8662 5.17454 14.0154V14.5315C5.17454 17.917 7.95664 20.6727 11.3745 20.6727H13.124V26.5795H10.1412C9.74442 26.5795 9.42415 26.8968 9.42415 27.2898C9.42415 27.6828 9.74442 28 10.1412 28H17.5386C17.9353 28 18.2556 27.6828 18.2556 27.2898C18.2556 26.8968 17.9353 26.5795 17.5386 26.5795H14.5557V20.6727H16.365C19.7829 20.6727 22.5649 17.917 22.5649 14.5315V14.0225C25.5621 14.0036 27.9952 11.5816 27.9952 8.59626L28 8.59863ZM1.43645 8.59863C1.43645 7.43384 2.39248 6.48449 3.5708 6.48449H5.17933V12.5902C3.09277 12.441 1.43882 10.7151 1.43882 8.59863H1.43645ZM21.1356 14.5339C21.1356 17.1357 18.9989 19.2546 16.3698 19.2546H11.3769C8.74776 19.2546 6.611 17.1381 6.611 14.5339V2.34142C6.611 1.83479 7.02687 1.42285 7.53836 1.42285H20.2059C20.7174 1.42285 21.1332 1.83479 21.1332 2.34142V14.5339H21.1356ZM22.5697 12.6044V6.48685H24.434C25.6099 6.48685 26.5683 7.43384 26.5683 8.61047C26.5683 10.8004 24.7782 12.5854 22.5721 12.6044H22.5697Z" fill="white" fillOpacity={pageState.endgame ? "1" : "0.5"}/>
                </svg> <p style={{color: getColor('endgame') }}>End Game</p>
            </span>
        </div>
        {
            endState && <div className="endGameModal_container">
            <div className="endGameModal">
                <h3>Ready to END your GAME?</h3>
                <p>This action cannot be undone.<br/>Once confirmed, the final scores will be displayed,and the game will conclude.</p>
                <button className="btnColored" onClick={()=>  endGame()}>I AGREE</button><br/>
                <button className="btnNormal" onClick={() => setEndState(!endState)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                        <path d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5L11 4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464466C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z" fill="#5D5D5D"/>
                    </svg>&nbsp;
                    Continue playing
                </button>
            </div>
        </div>
        }        
    </>
}
export default BottomNavigation;
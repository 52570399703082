import React, { useEffect, useState } from 'react';
import background from '../assets/images/home-screen-bg.png';
import logo from '../assets/images/Victoria-Park-Logo.png';
import '../assets/styles/App.scss';
import { supabase } from "../utils/supabase";



function App() {

  const [fileUrl, setFileUrl] = useState('');
  const [defaultTheme, setDefaultTheme] = useState(true);
  
  const getFlePublicUrl = async () => {

    const { data:theme, error } = await supabase
        .from('theme')        
        .select()
        .eq("status", true); 

  
     if(theme[0]?.background ){   
        if(theme[0]?.theme !== "default"){
          setDefaultTheme(prev => false);   
        }        
        let { data:image } = await supabase
                        .storage
                        .from('vpscorecard')
                        .getPublicUrl(theme[0]?.background)
    
        if(image && image.publicUrl){
          setFileUrl(image.publicUrl)
          
        }
     }

    
  } 

  const setThemeColor = async () => {
    const body = document.body;
    let themeColor = '#A9B892';
    if(body.classList.contains('spooky')){
      themeColor = '#453460';        
    }
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', themeColor);
    }
  };


  useEffect(()=>{
    
    localStorage.setItem("game_data", '');
    localStorage.setItem("player_data", '');   
    localStorage.setItem("agreed", false);

    getFlePublicUrl();
    //setThemeColor()

  }) 


  useEffect(() => {
    // const setThemeColor = () => {
    //   const body = document.body;
    //   let themeColor = '#A9B892';
    //   if(body.classList.contains('spooky')){
    //     themeColor = '#453460';        
    //   }
    //   const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    //   if (metaThemeColor) {
    //     metaThemeColor.setAttribute('content', themeColor);
    //   }
    // };

    // // Call the function initially
     setThemeColor();

    // // Add event listener for changes in color scheme preference
    // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', setThemeColor);

    // // Clean up function
    // return () => {
    //   window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', setThemeColor);
    // };
  }, []); // Empty dependency array to ensure effect runs only once

  useEffect(() => {
    const handleClassChange = () => {
      const body = document.body;            
      const metaThemeColor = document.querySelector('meta[name="theme-color"]');
      if (body.classList.contains('spooky')) {   
        console.log('---changin theme----')     
        metaThemeColor.setAttribute('content', '#453460');        
      } else {
          metaThemeColor.setAttribute('content', '#A9B892');        
      }
    };

    // MutationObserver to watch for changes in body class attribute
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          handleClassChange(); // Call your handler function when class changes
        }
      });
    });

    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  

                    
  return (
    <div className="VP-root" style={{background:`url(${fileUrl})`}}>
          <div className="VP-root-logo-holder">
            <img src={logo} className="VP-root-logo" alt="victoria-park"/>
          </div>

          {defaultTheme && <h1>
            PUTT PUTT SCORECARD
          </h1>}
          <div className="VP-root-button-holder">
            <div>
              <p className="home-note-head">Please note:</p>
              <p className="home-note-subhead">Don’t forget to choose your putter at the start of Hole 1!</p>
            </div>
            <a href="/rules" >LET'S PLAY</a>
          </div>
    </div>
  );
}

export default App;

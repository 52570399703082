import React, { useState, useEffect } from "react";
import add from "../../assets/images/add_small.png";
import { supabase } from "../../utils/supabase"; 
import DashboardComponent from "../../component/dashboard/Dashboard";
import "../../assets/styles/AdminRules.scss";
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'; //Example style, you can use another
import { Store } from 'react-notifications-component';

export default function AdminRules(){ 

    const [code, setCode] = useState(
        `<div class="text_block">
    <h4>PICK YOUR LEVEL</h4>
    <p>Each hole has three levels of difficulty.</p>
    <p>Choose which level you would like to play for the round.</p>
</div>
<div class="level">
    <span>Beginner</span>
    <span>Intermediate</span>
    <span>Advanced</span>
</div>
<div class="text_block">
    <h4>STROKE LIMIT</h4>
    <p>6 per hole, then move on.</p>
    
</div>
<div class="text_block">
    <h4>WRONG HOLE</h4>
    <p>Add one stroke to your score.</p>
</div>
<div class="text_block">
    <h4>SAND AND WATER HAZARDS</h4>
    <p>Retrieve ball and place one club head from where it entered the hazard.</p>
    <p>Add one penalty stroke to your score.</p>
    <p>Do not hit out of hazards.</p>
</div>
<div class="text_block">
    <h4>OBSTACLES</h4>
    <p>The ball may be moved up to one club head distance from obstacles or side walls, in direction of fairway.</p>
</div>
<div class="text_block">
    <h4>SAFETY</h4>
    <p>Do not enter water hazards, please retrieve the ball with retriever provided.</p>
    <p>If the ball is irretrievable please return to the Golf Shop for a replacement ball.</p>
    <p>No glass permitted on the course. Keep off rocks and out of gardens.</p>
    <p>No smoking.</p>
    <p>No swings above waist height.</p>
    <p>Maximum 4 players per group.</p>
</div>`);


    const getRules = async () => {
        let { data: rules } = await supabase
        .from('rules')
        .select('*');

        setCode( JSON.parse(rules[0].rules) )
    }


    useEffect( () => {
        getRules()
    },[])

    const save = async () => {        
        const { data, error } = await supabase
        .from('rules')
        .update({ rules: JSON.stringify(code) })
        .eq('id', 1)
        .select()

        if(data){
            Store.addNotification({
                title: "Success!",
                message: 'Rules Updated successfully',
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 4000,
                  onScreen: true,
                  click: true,
                  showIcon: true,
                  pauseOnHover: true
                }
            });
        }
        
    } 

    return <DashboardComponent>
            <div className="container_rules_settings">
                <h3>Rules</h3>               

                <Editor
                    value={code}
                    onValueChange={code => setCode(code)}
                    highlight={code => highlight(code, languages.js)}
                    padding={10}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                    }}
                    className="code-editor"
                />
                <br/>
                <div className="create_entry" onClick={()=>save()}>
                    <img src={add} style={{width: "15px", height: "15px"}}/><button> UPDATE</button>
                </div>                
                
            </div>
        </DashboardComponent>
}
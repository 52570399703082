import "./OrderModal.scss";
const OrderModal = (props) => {    
    return <div className="ordermodal">
        <h3>WORKING UP A THIRST</h3>
        <p>Fancy an ice cold beer, wine or soft drink?</p>
        <p>Choose from a range of refreshing beverages or tasty snacks and we’ll deliver them to you at the next hole!</p>
        <a href={props.data[0].url} className="anchorBtn" target="_blank">ORDER DRINKS OR SNACKS</a>
        <div className="commonFlex" onClick={()=>props.onClose()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
            <path d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5L11 4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464466C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z" fill="white"/>
        </svg>
        <p className="coloredText">CONTINUE PLAYING</p>
        </div>
    </div>
}
export default OrderModal;
import React , {useState} from "react";
import { supabase } from "../utils/supabase";
import { useNavigate } from 'react-router-dom';
import "../assets/styles/Login.scss";
export default function Login(){
    const history = useNavigate();
    const [formState, setFormState] = useState({
        email:"",
        password: ""
    });

    const [error, setError] = useState('');

    const loginValidation = async () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if(formState.email === ""){ setError('Email is required!') }
        else if(!emailRegex.test(formState.email)){ setError('Email is not valid!') }
        else if(formState.password === ""){ setError('Password is required!') }
        else{
            setError('')
            const { data, error } = await supabase.auth.signInWithPassword({
                email: formState.email,
                password: formState.password
              })
              
              if(data.session){
                localStorage.setItem("access_token", data.session.access_token);
                localStorage.setItem("refresh_token", data.session.refresh_token);
                localStorage.setItem("user", data.session.user.email);
                history('/dashbaord');
              }
              if(error){
                setError('Invalid login credentials!')
              }
        }

    
    }

    return <div className="login_container">
        <label className="heading">Login</label>
        <input type="email" onChange={(field)=>setFormState( prev => ({...prev, email: field.target.value }))} className="login-field" placeholder="Email"/>
        <input type="password" onChange={(field)=>setFormState( prev => ({...prev, password: field.target.value }))} className="login-field"  placeholder="password"/>
        <div className="btn-checkbox">
          <input type="checkbox" className="checkbox"/> <label>Remeber me</label>
        </div>
        { error.length > 0 && <label className="small-error">{error}</label>}
        <input type="button" className="login-btn" value={"Login"} onClick={() => loginValidation()}/>
    </div>
}
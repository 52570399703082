import React, { useState, useEffect } from "react";
import { supabase } from "../utils/supabase";
import BottomNavigation from "../component/bottom-navigation/BottomNavigation";
import { useNavigate } from 'react-router-dom';
import { Store } from 'react-notifications-component'
import "../assets/styles/Players.scss";

const Players = () => {

    const [players, setPlayers] = useState([]);
    const [gameInProgress, setGameInProgress] = useState(false);
    const history = useNavigate();

    const setPlayersData = (value, index) => {
        let tempPlayers = players;
        tempPlayers[index] = value.substring(0, 10);
        setPlayers([...tempPlayers]);
    }

    const setThemeColor = async () => {
        const body = document.body;
        let themeColor = '#A9B892';
        if(body.classList.contains('spooky')){
          themeColor = '#F68A43';        
        }
        const metaThemeColor = document.querySelector('meta[name="theme-color"]');
        if (metaThemeColor) {
          metaThemeColor.setAttribute('content', themeColor);
        }
      };

    const getGameInfo = async (gameId) => {

        const { data: gameInfo } = await supabase
        .from('games')
        .select()
        .eq("id", gameId);

        if(gameInfo[0].status === "inProgress"){
            setGameInProgress(true);
        }

    }

    // useEffect(()=>{  
    
    //     //setThemeColor()
    
    //   },[])

    useEffect(() => {
        const game_data = localStorage.getItem("game_data");
        const player_data = localStorage.getItem("player_data");  
        const agreed = localStorage.getItem("agreed");      

        if((game_data === null || game_data.length < 1) && !agreed ){
            history('/');
        }

        if(game_data) {
            const oldGame =JSON.parse(game_data);
            if(oldGame && oldGame.length > 0){
                getGameInfo(oldGame[0].id)
            }
        }

        if(player_data) {
            const resp = JSON.parse(player_data).map(pl=> pl.name)
            setPlayers(resp)
        }
    },[history])

    useEffect(() => {       
        setThemeColor();
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', setThemeColor);
        return () => {
          window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', setThemeColor);
        };
      }, []);


    const createGame = async (playersData) => {
        //this logic is responsible for creating and inserting record in "games" table
        const playersObj = [];
        await playersData.map(player => {
              if(player && player !==""){
                 playersObj.push(player)
              }
         });
        if(playersObj.length > 0){
            const { data } = await supabase
            .from('games')
            .insert([
                { player_count: playersObj.length, status: 'inProgress' },
            ])
            .select();
            return data;
        }        
    }

    const createPlayers = async (playersData, gameId) => {
        //this logic is responsible for creating player entrie in players table
        const playersObj = [];
        await playersData.map(player => {
            if(player && player !==""){
                playersObj.push({name: player, game_id: gameId} )
            }
        });
        if(playersObj.length > 0){
            const { data } = await supabase.from('players').insert(playersObj).select();
            return data;
        }
    }

    const validateAndCreateGame = () => {
        if(players.length < 1){
            Store.addNotification({
                title: "Error!",
                message: "Atleast 1 players required",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 4000,
                  onScreen: true,
                  click: true,
                  showIcon: true,
                  pauseOnHover: true
                }
              });
        } else {
            createGame(players).then( gameData => {
                localStorage.setItem("game_data", JSON.stringify(gameData));
                if(gameData){
                    createPlayers(players, gameData[0].id).then( playerData => {
                        localStorage.setItem("player_data", JSON.stringify(playerData));
                        history('/enter-score');
                    } )
                } else {
                    Store.addNotification({
                        title: "Error!",
                        message: "Atleast 1 players required",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 4000,
                          onScreen: true,
                          click: true,
                          showIcon: true,
                          pauseOnHover: true
                        }
                      });
                }
            })
        }
    }




  useEffect(() => {
    const handleClassChange = () => {
      const body = document.body;            
      const metaThemeColor = document.querySelector('meta[name="theme-color"]');
      if (body.classList.contains('spooky')) {   
        console.log('---changin theme----')     
        metaThemeColor.setAttribute('content', '#F68A43');        
      } else {
          metaThemeColor.setAttribute('content', '#A9B892');        
      }
    };

    // MutationObserver to watch for changes in body class attribute
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          handleClassChange(); // Call your handler function when class changes
        }
      });
    });

    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);




    return <><div className="VP-playes">

        <div className="players_content">
            <h2>PLAYERS</h2>
            <h4>TAP TO  ENTER THE NAMES<br/>OF EACH PLAYERS BELOW</h4>

            <div className="field_box">
                <h4>PLAYER 1</h4>
                <input type="text" disabled={gameInProgress} placeholder="Enter Name" className="input_field" onChange={(e) => setPlayersData(e.target.value, 0)} value={players[0]}/>
            </div>
            <div className="field_box">
                <h4>PLAYER 2</h4>
                {
                    players[0] || players[1] ? <input disabled={gameInProgress || !players[0]} type="text" placeholder="Enter Name" className="input_field" onChange={(e) => setPlayersData(e.target.value, 1)} value={players[1]}/> : <p className="placeholder">Enter Name</p>
                }
                
            </div>
            <div className="field_box">
                <h4>PLAYER 3</h4>
                {
                    players[1] || players[2] ? <input type="text" disabled={gameInProgress || !players[1]}  placeholder="Enter Name" className="input_field" onChange={(e) => setPlayersData(e.target.value, 2)} value={players[2]}/> : <p className="placeholder">Enter Name</p>
                }
            </div>
            <div className="field_box">
                <h4>PLAYER 4</h4>
                {
                    players[2] || players[3] ? <input type="text" disabled={gameInProgress || !players[2]} placeholder="Enter Name" className="input_field" onChange={(e) => setPlayersData(e.target.value, 3)} value={players[3]}/> : <p className="placeholder">Enter Name</p>
                }
                
            </div>

            <p className="small">Maximum 4 players per group</p>
            <p className="highlighted">Ready?</p>
            <p className="normal">Have you collected your golf balls from inside the Golf Shop?</p>
            <p className="normal">Don’t forget to choose your putter at the start of Hole 1!</p>
            {
                !gameInProgress && <button onClick={ () => validateAndCreateGame()}>Tee OFF</button>
            }
            
        </div>
    </div>
    {
        gameInProgress && <BottomNavigation/>
    }
    </>
}

export default Players;
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import { ReactNotifications } from 'react-notifications-component'
import './index.css';
import App from './pages/Home';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Rules from "./pages/Rules";
import Players from './pages/Players';
import EnterScore from './pages/EnterScore';
import ScoreCard from './pages/ScoreCard';
import Result from './pages/Results';
import ResultsShared from "./pages/ResultsShared";
import Login from "./pages/Login";
import Dashbaord from "./pages/Dashboard/index";
import HomePageSettings from "./pages/Dashboard/HomePageSettings";
import OrderModalSettings from "./pages/Dashboard/OrderModalSettings";
import AdminRules from "./pages/Dashboard/Rules";
import ThemeSelection from './pages/Dashboard/ThemeSelection';

import 'react-notifications-component/dist/theme.css'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <div className={`app-container`}>
  <BrowserRouter>
    {/* <App /> */}
    <ReactNotifications />
    <Routes>
        <Route path="/" element={<App />}/>
        <Route path="/rules" element={<Rules />} />
        <Route path="/players" element={<Players />} />
        <Route path="/enter-score" element={<EnterScore />} />
        <Route path="/view-score" element={<ScoreCard />} />
        <Route path="/result" element={<Result/>} />
        <Route path="/result/:id" element={<ResultsShared/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/dashbaord" element={<Dashbaord/>} />
        <Route path="/dashboard/home-setting" element={<HomePageSettings/>} />
        <Route path="/dashboard/order-modal-setting" element={<OrderModalSettings/>} />
        <Route path="/dashboard/rules" element={<AdminRules/>} />
        <Route path="/dashboard/theme" element={<ThemeSelection/>} />
    </Routes>
  </BrowserRouter>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

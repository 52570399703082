import React, { useEffect, useState, useRef } from "react";
import { supabase } from "../utils/supabase";
import { useNavigate } from 'react-router-dom';
import BottomNavigation from "../component/bottom-navigation/BottomNavigation";
import Star from "../assets/images/Star.png";
import Circle from "../assets/images/circle.png";
import Par from "../assets/images/Line.png";
import Bogey from "../assets/images/Rectangle.png";
import DoubleBogey from "../assets/images/doublebogey.png";

import "../assets/styles/ScoreCard.scss";
const ScoreCard = () => {
    const history = useNavigate();
    const [players, setPlayers] = useState([]);
    const [game, setGame] = useState();
    const [scoreToDisplay, setScoreToDisplay] = useState({});
    const [allPar, setAllPar] = useState([]);
    const containerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {        
        const setThemeColor = () => {
          const themeColor = '#F2F2F2';
          const metaThemeColor = document.querySelector('meta[name="theme-color"]');
          if (metaThemeColor) {
            metaThemeColor.setAttribute('content', themeColor);
          }
        };
    
        // Call the function initially
        setThemeColor();
    
        // Add event listener for changes in color scheme preference
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', setThemeColor);
    
        // Clean up function
        return () => {
          window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', setThemeColor);
        };
      }, []); // Empty dependency array to ensure effect runs only once

 
    const loopArray = new Array(18).fill(null);
    const date = new Date();

    useEffect(() => {
        const game_data = localStorage.getItem("game_data");
        const player_data = localStorage.getItem("player_data");
        const agreed = localStorage.getItem("agreed");

        if(game_data === null || game_data.length < 1) {
             if(agreed){
                history('/players');
            }
            else{
                history('/');
            }
        }

        if(game_data) {
            setGame(JSON.parse(game_data))
        }

        if(player_data) {
            setPlayers(JSON.parse(player_data))
        }

        getPar();       

    },[history])

    const getGameScores = async (thisGame) => {
        let { data: gamesScoresResponse } = await supabase
        .from('game_meta')
        .select("*")
        .eq('game_id', thisGame);
        const resolvedPromise = Promise.resolve(gamesScoresResponse)
        const resp = await resolvedPromise.then((value) => {
           return value;
        });
        return resp;
    }
    

    useEffect(() => {
        if(game && game.length > 0) {            
            getGameScores(game[0].id).then( data => {
                let tempObj = {};
                // eslint-disable-next-line 
                loopArray.map( (_item, index) => {
                    const filteredArray = data.filter(score => score.hole_number === index+1);
                    const uniqueIds = new Set();
                    const uniqueObjectsArray = filteredArray.filter(obj => {                        
                        if (!uniqueIds.has(obj.player_id)) {
                            uniqueIds.add(obj.player_id);
                            return true;
                        }
                        return false;
                    });
                   players.map( player => uniqueObjectsArray.findIndex(obj => obj.player_id === player.id ) === -1 && uniqueObjectsArray.push({
                    game_id: 0,
                    hole_number: index+1,
                    id: 0,
                    player_id: player.id,
                    score: 0
                   }) )
                    
                    tempObj[index] = uniqueObjectsArray.sort((a, b) => a.player_id - b.player_id);
                })
                setScoreToDisplay(tempObj);
            })                        
        }
        // eslint-disable-next-line
    },[game])
 
    const firstNine = (tabIndex) => {
        let score = 0;
        // eslint-disable-next-line 
        Object.keys(scoreToDisplay).map((_data, index) => {
            if(index < 9) {
                score += scoreToDisplay[index][tabIndex].score
            }            
        })
        return score > 0 ? score : '';
    }

    const lastNine = (tabIndex) => {
        let score = 0;
        // eslint-disable-next-line 
        Object.keys(scoreToDisplay).map((_data, index) => {
            if(index > 8) {
                score += scoreToDisplay[index][tabIndex].score
            }            
        })
        return score > 0 ? score : '';
    }



    const handleScroll = () => {
        setScrollPosition(containerRef.current.scrollLeft);
    };

    const getPar = async () => {

        let { data: gamePar, error } = await supabase
        .from('game_par')
        .select("*")

        setAllPar(gamePar);
    }

    const getBg = (holeId, score) => {
        let shape = "";
        if(score === 1){
            shape = { shape : Star, par: false};
        } else  {
            const holeParObj = allPar.find( data => data.hole === holeId);
            if(holeParObj){
                if (holeParObj.par-1 === score){  shape = { shape : Circle, par: false}; }
                if (holeParObj.par === score){ shape = { shape : Par, par: true}; }
                if (holeParObj.par+1 === score){ shape = { shape : Bogey, par: false}; }
                if (holeParObj.par+2 === score){ shape = { shape : DoubleBogey, par: false}; }
            }
        }
        return shape;        
    }


    return <div className="VP-view-score">
            <div className="view-score-content">
                    <h1>{`${date.getDate()} ${date.toLocaleString('en-US', { month: 'long' })} ${date.getFullYear()}`}</h1>
                    <div className="rankflex">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34" fill="none">
                                <path d="M17.5 2.02839L21.1858 8.53283L21.5277 9.13633L22.2128 9.02742L29.5962 7.85361L26.8088 14.7907L26.5502 15.4343L27.0625 15.902L32.5837 20.9428L25.4222 23.0887L24.7577 23.2878L24.7115 23.98L24.2129 31.4394L18.07 27.1783L17.5 26.783L16.93 27.1783L10.7871 31.4394L10.2885 23.98L10.2423 23.2878L9.57781 23.0887L2.41629 20.9428L7.9375 15.902L8.44977 15.4343L8.19115 14.7907L5.40381 7.85361L12.7872 9.02742L13.4723 9.13633L13.8142 8.53283L17.5 2.02839Z" stroke="#F2AE1D" stroke-width="2"/>
                            </svg>
                            <p>Hole-in-one</p>
                        </span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                <circle cx="15" cy="15" r="14" stroke="#367E84" stroke-width="2"/>
                            </svg>
                            <p>Birdie</p>
                        </span>
                        <span>
                            <svg width="30" height="2" viewBox="0 0 30 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="30" y1="1" x2="8.74228e-08" y2="1" stroke="#5D5D5D" stroke-width="2"/>
                            </svg>
                            <p>Par</p>
                        </span>
                        <span>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="28" height="28" stroke="#C88AA8" stroke-width="2"/>
                            </svg>
                            <p>Bogey</p>
                        </span>
                        <span>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="28" height="28" stroke="#C16628" stroke-width="2"/>
                                <svg width="28" height="28" viewBox="-3 -3 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="23" height="23" stroke="#C16628"/>
                                </svg>
                            </svg>
                            <p>Double Bogey</p>
                        </span>
                    </div>

                    <div
                        ref={containerRef}
                        className="scroll-container"
                        onScroll={handleScroll}
                    >

                            <div className="score-table table-container">
                                <div className="first-grid-row">
                                    <div>HOLE</div>
                                    {
                                        players.map( player => <div>{player.name.length > 8 ? player.name.substring(0, 8)+".." : player.name }</div> )
                                    }                                           
                                </div>
                                {
                                    loopArray.map((item, index) => {
                                        return index < 9 && <div className="data-grid-row">                                                                 
                                            <div>{index+1}</div>
                                            {
                                                scoreToDisplay && scoreToDisplay[index] && scoreToDisplay[index].length === players.length && scoreToDisplay[index].map( (data, scIndex) => {
                                                    const shapeResponse = getBg(index+1, data.score);    
                                                    let tempclass ="";
                                                    if(shapeResponse && shapeResponse.par === true){ tempclass = "parElement"; }
                                                    return <div className={tempclass} style={{background:`url(${shapeResponse.shape})`}}>{ data.score > 0 && data.score}</div> 
                                                })
                                            }
                                            {
                                                (!scoreToDisplay[index] || scoreToDisplay[index].length === 0 ) && new Array(players.length).fill(null).map( ()=> <div></div> )
                                            }                                            
                                        </div>
                                    })
                                }

                                <div className="data-grid-row">                                                                 
                                    <div>SUB<br/>TOTAL</div>                                     
                                    {
                                        new Array(players.length).fill(null).map( (d, i)=> <div>{firstNine(i)}</div> )
                                    }                                            
                                </div>  

                            </div>


                            <div className="score-table table-container">
                                <div className="first-grid-row">
                                    <div>HOLE</div>
                                    {
                                        players.map( player => <div>{player.name.length > 8 ? player.name.substring(0, 8)+".." : player.name }</div> )
                                    }
                                                
                                </div>
                                {
                                    loopArray.map((item, index) => {
                                        return index > 8 && <div className="data-grid-row">                                                                 
                                            <div>{index+1}</div>
                                            {
                                                scoreToDisplay && scoreToDisplay[index] && scoreToDisplay[index].length === players.length && scoreToDisplay[index].map( (data, scIndex) => {
                                                    const shapeResponse = getBg(index+1, data.score);    
                                                    let tempclass ="";
                                                    if(shapeResponse && shapeResponse.par === true){ tempclass = "parElement"; }
                                                    return <div className={tempclass} style={{background:`url(${shapeResponse.shape})`}}>{ data.score > 0 && data.score}</div> 
                                                })
                                            }
                                            {
                                                (!scoreToDisplay[index] || scoreToDisplay[index].length === 0 ) && new Array(players.length).fill(null).map( ()=> <div></div> )
                                            }                                            
                                        </div>
                                    })
                                }

                                <div className="data-grid-row">                                                                 
                                    <div>SUB<br/>TOTAL</div>                                     
                                    {
                                        new Array(players.length).fill(null).map( (d, i)=> <div>{lastNine(i)}</div> )
                                    }                                            
                                </div>  

                            </div>
 
                    </div>
                    <div className="bullet-points">
                        <span className={scrollPosition === 0 ? 'active' : ''} onClick={()=>{ containerRef.current.scrollLeft = 0} }></span>
                        <span className={scrollPosition !== 0 ? 'active' : ''} onClick={()=>{ containerRef.current.scrollLeft = 500 }}></span>
                    </div>
            </div>
            <BottomNavigation/>
        </div>
}
export default ScoreCard;
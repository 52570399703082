import React , {useState, useEffect} from "react";
import DashboardComponent from "../../component/dashboard/Dashboard";
import { supabase } from "../../utils/supabase";
import { Store } from 'react-notifications-component'
import "../../assets/styles/HomePageSettings.scss";

export default function HomePageSettings(){
    const [file, setFile] = useState();
    const [currentFile, setCurrentFile] = useState('');
    const [filesToRemove, setFilesToRemove] = useState([]);

    const getFlePublicUrl = async () => {

        const { data } = await supabase
                .storage
                .from('vpscorecard')
                .list('', {
                    limit: 100,
                    offset: 0,
                    sortBy: { column: 'name', order: 'asc' },
                })
                
         const file = data;
         if(file.length > 0){
            let { data } = await supabase
                            .storage
                            .from('vpscorecard')
                            .getPublicUrl(file[0].name)
        
            if(data && data.publicUrl){
                setCurrentFile(data.publicUrl)
            }
         }

        
      }
    const getBucketDetails = async () => {
        const { data, error } = await supabase
                .storage
                .from('vpscorecard')
                .list('', {
                    limit: 100,
                    offset: 0,
                    sortBy: { column: 'name', order: 'asc' },
                })
         if(data.length > 0){
            const filesToRemove = [];
            data.map( file => filesToRemove.push(file.name))
            setFilesToRemove(filesToRemove);
         }
    }

    useEffect(()=>{
        getFlePublicUrl();
        getBucketDetails();
    },[])

    const saveFile = async () => {

        if(file){


            await supabase
            .storage
            .from('vpscorecard')
            .remove(filesToRemove)
           
            const { data, error } = await supabase
                .storage
                .from('vpscorecard')
                .upload(file.name, file, {
                    cacheControl: '0',
                    upsert: true
                })
                if(data){
                    Store.addNotification({
                        title: "Success",
                        message: "Image uploaded successfully",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 4000,
                          onScreen: true,
                          click: true,
                          showIcon: true,
                          pauseOnHover: true
                        }
                    })
                    getFlePublicUrl();
                    getBucketDetails();
                }
 

                if(error){
                    Store.addNotification({
                        title: "Error!",
                        message: "An error occured, Please try again!",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 4000,
                          onScreen: true,
                          click: true,
                          showIcon: true,
                          pauseOnHover: true
                        }
                    });
                }
        } else {
            Store.addNotification({
                title: "Error!",
                message: "No image selected",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 4000,
                  onScreen: true,
                  click: true,
                  showIcon: true,
                  pauseOnHover: true
                }
            });
        }
    }

    return <DashboardComponent>
            <div className="container_home_settings">
                <h3>Home page settings</h3>

                <label className="form-label">Background image</label>
                <input type="file" placeholder="Home page background" onChange={(file)=>setFile(file.target.files[0])} accept="image/*" />

                <input type="button" value={"save"} onClick={()=>saveFile()}/>
                <br/><br/>
                <label className="form-label">Current image</label>
                <img src={currentFile}  style={{width:"200px"}}/>
            </div>            
        </DashboardComponent>
}
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from "../utils/supabase";
import First from "../assets/images/first.png";
import Second from "../assets/images/second.png";
import Third from "../assets/images/third.png";
import Fourth from "../assets/images/fourth.png";
import "../assets/styles/Result.scss";

const ResultsShared = () => {
    const history = useNavigate();
    const [scoreToDisplay, setScoreToDisplay] = useState([]);
    const [players, setPlayers] = useState([]);    
    const loopArray = new Array(18).fill(null);
    const [gameError, setGameError] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        if(id) {     
            getGameDetails(id).then( data => {
                if(data[0] && data[0].status === "inProgress" ){
                    setGameError(true);
                }
            })
            getGameScores(id).then( data => {
                // eslint-disable-next-line 
                let final = [];
                loopArray.map( (_item, index) => {
                    const filteredArray = data.filter(score => score.hole_number === index+1);
                    const uniqueIds = new Set();

                    const uniqueObjectsArray = filteredArray.filter(obj => {                        
                        if (!uniqueIds.has(obj.player_id)) {
                            uniqueIds.add(obj.player_id);
                            return true;
                        }
                        return false;
                    })

                    uniqueObjectsArray.map( array => {
                        const fIndex = final.findIndex( f => f.player == array.player_id);
                        if(fIndex > -1) {
                            final[fIndex] = { player : array.player_id, score: final[fIndex].score+array.score}
                        } else {
                            final.push({ player : array.player_id, score: array.score})
                        }
                    })

                })
                final = final.sort( (a,b) => a.score-b.score)
                setScoreToDisplay(final);
            })
            getGamePlayers(id).then( data => setPlayers(data) )
        } else {
            history('/');
        }
    },[history])
    
 
    const getGameScores = async (thisGame) => {

        let { data: gamesScoresResponse } = await supabase
        .from('game_meta')
        .select("*")
        .eq('game_id', thisGame);
        const resolvedPromise = Promise.resolve(gamesScoresResponse)
        const resp = await resolvedPromise.then((value) => {
           return value;
        });
        return resp;

    }

    const getGamePlayers = async (thisGame) => {
        let { data: gamesScoresResponse } = await supabase
        .from('players')
        .select("*")
        .eq('game_id', thisGame);

        const resolvedPromise = Promise.resolve(gamesScoresResponse)
        const resp = await resolvedPromise.then((value) => {
           return value;
        });
        return resp;
    }

    const getGameDetails = async (thisGame) => {
        let { data: gameData } = await supabase
        .from('games')
        .select("*")
        .eq('id', thisGame);
        const resolvedPromise = Promise.resolve(gameData)
        const resp = await resolvedPromise.then((value) => {
           return value;
        });
        return resp;
    }

    const getName =  (playerId) => {
        const findResponse = players.find(pl => pl.id == playerId);
        let resp = '';
        if(findResponse){
            resp = findResponse.name.length > 7 ? findResponse.name.substr(0,7) : findResponse.name;
        }
        return resp;
    }

    return <><div className="VP-view-result">
        <div className="view-result-content">
                <h1>LEADERBOARD</h1>
                <p>Last on the scorecard picks up the bill at the Bistro?!</p>
                <p>Satisfy your cravings post-game with woodfired pizza and refreshing beverages</p>
            {
                scoreToDisplay.map( (data, index) => <div className="scoreRow">
                <div className="firstDiv">
                    <img src={index === 0 ? First : index === 1 ? Second : index === 2 ? Third : index === 3 ? Fourth : ""} />
                    <h2>
                        {getName(data.player)}
                    </h2>
                </div>
                <div className="secondDiv"><h1>{data.score}</h1></div>
            </div>)
            }            
        </div>
    </div>
    {
        gameError && <div className="modalErrorContainer">

            <div className="modalError">
                <h1>This game is still in progress...</h1>
                <a href="/">Back To Home</a>
            </div>
        </div>
    }
    </>
}
export default ResultsShared;
import {useEffect} from "react";
import "./Dashboard.scss";
import logo from '../../assets/images/Victoria-Park-Logo.png';
import logoutImage from '../../assets/images/logout.png';
import { useNavigate } from 'react-router-dom';
export default function DashboardComponent({children}){
    const history = useNavigate();
    
    useEffect(() => {
        if(!localStorage.getItem("access_token")){
            history('/login');
        }
    },[])

    const logout = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
        history('/login');
    }

    return <div className="container">

        <div className="topBar">
            <img src={logo} className="VP-logo" alt="victoria-park"/>
            <div>
                <label>Welcome, {localStorage.getItem("user")}</label>
                <img src={logoutImage} className="VP-logout" alt="logout" onClick={()=>logout()} />
            </div>
        </div>
        <div className="sidebar_contianer_holder">

            <div className="sidebar">
                <h2>Setting</h2>
                <a href="/dashboard/home-setting">Home Page</a>
                <a href="/dashboard/order-modal-setting">Order Modal</a>
                <a href="/dashboard/rules">Rules</a>
                <a href="/dashboard/theme">Theme</a>
                    
            </div>
            <div className="center_content">
                {children}
            </div>

        </div>

    </div>
}
